.time-line-ctnr {
  max-height: 400px;
  min-height: 50px;
  -webkit-mask-image: linear-gradient(180deg, #000 90%, transparent);
  mask-image: linear-gradient(180deg, #000 90%, transparent);
  overflow-y: scroll;
  .time-line {
    position: relative;
    margin: 0 0 0px 0;
    padding: 0;
    list-style: none;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: -10px;
      width: 2px;
      background: #536171;
      left: 32px;
      border-radius: 2px;
    }
    &:first-child:before {
      top: 3px;
    }
    &:last-child:before {
      bottom: 18px;
    }
    > li {
      position: relative;
      margin-right: 0;
      > .time-line-item {
        color: #444;
        margin-right: 15px;
        position: relative;
        margin-left: 50px;
        .time-line-time {
          font-size: 12px;
          color: #999;
          margin-bottom: 10px;
        }
        .time-line-header {
          margin: 0;
          color: #555;
          line-height: 1.1;
        }
      }
      > .fa {
        position: absolute;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        color: #666;
        background: #536171;
        left: 28px;
        top: 3px;
        border: 2px solid #536171;
        display: inline-block;
        box-sizing: border-box;
        &.first {
          background: #f7f9fa;
        }
      }
    }
  }
}
