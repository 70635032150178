.bars {
  box-sizing: border-box;
  width: 98%;
  height: 15px;
  position: relative;
  display: block;
  .bar {
    margin-top: 2px;
    float: left;
    height: 10px;
    cursor: pointer;
    &:only-of-type {
      border-radius: 5px;
    }
    &:first-of-type {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-of-type {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    &:hover .tooltiptext {
      visibility: visible;
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  background-color: #192532;
  border-radius: 2px;
  font-size: 12px;
  padding: 5px 8px;
  top: 20px;
  width: auto;
}

.tooltiptext {
  visibility: hidden;
  color: #fff;
  text-align: center;
  position: relative;
  z-index: 1;
  justify-self: center;
  left: 46%;
  background-color: #192532;
  border-radius: 2px;
  font-size: 12px;
  padding: 5px 8px;
  top: 20px;
  width: auto;

  &:before {
    background: #192532;
    content: '';
    height: 9px;
    left: 50%;
    position: absolute;
    top: 0px;
    transform: rotate(45deg) translateX(-50%);
    width: 9px;
    z-index: 1;
  }
}
