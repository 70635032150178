@import '@contentful/f36-tokens/dist/css/index.css';

body {
  margin: 0;
  padding: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Helvetica,
    Arial,
    sans-serif,
    Apple Color Emoji,
    Segoe UI Emoji,
    Segoe UI Symbol;
}

.notice-wrapper {
  position: fixed;
  z-index: 1;
  width: 100%;
}

.notice {
  padding: 10px;
  width: 100%;
  .pb-4 {
    padding-bottom: 4px;
  }
  a {
    text-decoration: none;
    color: #2e75d4;
  }
  div:nth-child(2) {
    width: 100%;
  }
}

.notice-close {
  position: absolute;
  right: 2%;
  border: none;
  height: 2em;
  width: 2em;
}

.localised-references-container,
.embedded-reference-container {
  background-color: rgb(247, 249, 250);
  border: 1px solid rgb(229, 235, 237);
  border-radius: 2px;
  padding-left: 1rem;
  padding-top: 1rem;
  transition-duration: 0.2s;
  transition-property: max-height, padding-top, padding-bottom, opacity;
  transition-timing-function: ease-in-out;
  max-height: 11em;
  flex-direction: column;
  &.new-fields {
    margin-bottom: 5px;
    padding-top: 10px;
  }
  > div {
    overflow-y: auto;
  }
}

.listOnScroll {
  max-height: 49vh;
  overflow-y: auto;
  padding-left: 0px;
  margin-top: 0px;
}

.external-link {
  display: block;
  margin-top: var(--spacing-m);

  &:hover {
    svg {
      fill: var(--color-text-dark);
    }
  }

  svg {
    vertical-align: sub;
    margin-right: var(--spacing-2xs);
    transition: fill 0.1s ease-in-out;
  }
}

.tag-loader {
  height: 10px;
  width: 120px;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--spacing-l);
}

.general-error {
  margin-top: var(--spacing-l);

  a {
    margin-top: var(--spacing-2xs);
    display: block !important;
  }
}

.sidebar-container {
  min-height: 200px;

  .translator-button {
    min-width: 100vw;

    &-80 {
      width: 80vw;
      border-right: none;
      border-bottom-right-radius: 0;
      -moz-border-radius-bottomright: 0;
      -webkit-border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      -moz-border-radius-topright: 0;
      -webkit-border-top-right-radius: 0;
    }

    &-20 {
      width: 20vw;
      border-left: none;
      border-bottom-left-radius: 0;
      -moz-border-radius-bottomleft: 0;
      -webkit-border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      -moz-border-radius-topleft: 0;
      -webkit-border-top-left-radius: 0;
    }
  }
}

.completion-info {
  align-items: flex-end;
}

span.status,
div.entry-status {
  float: right;
  text-align: right;
  &.inactive {
    color: #f79b0c;
  }

  &.active {
    color: #59c895;
  }

  &.archived {
    color: #2e75d4;
  }
}

.mt-2 {
  margin-top: var(--spacing-xl) !important;
}

hr.faded {
  opacity: 0.2;
}

.selection-info-row {
  background: #fdf9e8;
  padding: 5px 16px;
  font-size: 13px;
  border-top: 1px solid #dcdfe6;
  border-left: 1px solid #dcdfe6;
  border-right: 1px solid #dcdfe6;
  color: #d3aa3b;
  font-weight: 700;
}

.table-row-selected {
  background-color: rgb(232, 247, 255);
}

.dot {
  height: 12px;
  width: 12px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

//-----------------------------------------------
.status-row {
  display: flex;
  align-items: center;
}

.status-row .col {
  flex: 1;
}

.status-row .col-1 {
  flex: 0 0 8.333333%;
}

.status-row .col-9 {
  flex: 0 0 75%;
}

.status-row .col-2 {
  flex: 0 0 16.666667%;
}


//---------------------------------------------
#details,
#translation-editor {
  .app {
    margin-top: 0;
    .body {
      margin: 0 auto;
      padding: 0px 24px 100px;
      .section {
        padding: 0;
        .tooltip-icon {
          margin-left: 0.5rem;
        }
      }
    }
  }
  /** Source entry selector **/
  [data-test-id='cf-ui-entity-list-item'] {
    figure {
      display: none;
    }
    height: 4rem;
    p {
      font-size: 0.875rem;
      color: #192532;
      font-weight: bold;
      margin: 0;
    }
    h1 {
      font-size: 0.875rem;
      color: #192532;
      font-weight: normal;
    }
  }
  [data-test-id='cf-ui-field-group'] {
    margin-bottom: var(--spacing-xl);
  }
}
.translator-editor,
#details,
#translation-editor {
  label {
    color: #6b7888;
    font-weight: normal;
  }
}
.padding-cell.row > div {
  padding: 0.5em 1em;
}
.padding-cell.row {
  margin: 0;
}

.entry-table-head {
  margin-left: 0.3rem;
}

.entry-table-head,
.accordion__button,
.accordion__item:focus,
.entry-table-head:focus,
.accordion__button:focus {
  outline: none;
  cursor: pointer;
  flex-grow: 2;
}
.app {
  .body {
    height: auto;
    min-height: 65vh;
    margin: var(--spacing-m) auto 0;
    padding: var(--spacing-xl) var(--space-2xl);
    background-color: white;
    z-index: 2;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 2px;

    &.no-shadow {
      box-shadow: none;
    }

    .config {
      display: flex;
      flex-direction: column;

      &:not(.no-padding) {
        padding: var(--spacing-xl) 0;
      }

      hr {
        margin-top: var(--spacing-l);
        margin-bottom: var(--spacing-l);
        border: 0;
        width: 100%;
        height: 1px;
        background-color: var(--color-element-mid);
      }

      .about-p {
        margin: var(--spacing-s) 0 0;
      }

      &.setup p {
        margin: var(--spacing-m) 0;
      }

      .ts-text-field {
        width: 97%;

        p {
          margin-top: var(--spacing-xs);
          margin-bottom: var(--spacing-l);
        }
      }

      .section {
				width: 99%;

        & .getting-started label {
          margin-top: var(--spacing-l);
        }
      }
    }
  }

  .background {
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    width: 100%;
    height: auto;
    min-height: -webkit-fill-available;
    background-size: cover;
    background: url(https://my.acclaro.com/images/login-page.jpg) 50% 100%;
  }
}

.logo {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
  img {
    opacity: 0.5;
    width: 200px;
  }
}

.switch-view {
  display: flex;
  align-items: center;
  justify-content: center;
}

.review-entry-container {
  max-height: 70vh;
  th[data-test-id='cf-ui-table-cell'] {
    padding: 0.625rem 1.25rem 0.625rem 0.525rem;
  }

  .accordion {
    .accordion__item:first-child {
      margin-top: 0px !important;
    }
  }
}

.flex-cell {
  -webkit-box-align: center;
  align-items: center;
  display: flex !important;
}

.table-cell-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.map-container {
  height: 480px;
  overflow: hidden;
  transition: 0.5s;
}

.hide-trans {
  height: 0px !important;
  transition: 0.5s;
  overflow: hidden;
}

.align-center-tab {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  text-align: center;
}

.editor-tabs {
  background: white;
  z-index: 2;
  [data-test-id='cf-ui-icon'] {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
}
.lh-1 {
  line-height: 1;
}

.modal-label {
  font-style: italic;
  font-weight: normal;
  font-size: 10px;
  line-height: 21px;
  color: #8091a5;
  width: 100%;
  padding-left: 1.3rem;
  display: flex;
  align-items: center;
}

.last-delivered {
  font-weight: normal;
  font-size: 0.7rem;
  line-height: 21px;
  color: #8091a5;
}

.language-completion,
.insight-text {
  @extend .last-delivered;
  font-style: initial;

  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.project-status {
  @extend .language-completion;
}

.translation-sidebar-info {
  @extend .language-completion;
  letter-spacing: initial;
}

.accordion__item [data-test-id='cf-ui-editor-toolbar'] {
  align-items: flex-start;
  flex-direction: column;
  border: 0;
  border-bottom: 1px solid #e5ebed;
}

.accordion-entry-name {
  display: flex;
  justify-content: space-between;
}

.details-toolbar {
  padding: 1rem;
  justify-content: space-between;
  border: 1px solid lightgray;
}

.entry-modal-head-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.modal-label [data-test-id='cf-ui-icon'] {
  height: 13px;
}

.modal-entry-name {
  font-size: x-small;
  display: flex;
  justify-content: space-between;
  [data-test-id='cf-ui-icon'] {
    height: 13px;
  }
}
.padding-cell.row > div {
  padding: 0.7em 1em;
  border-bottom: 1px solid #e5ebed;
  &:first-child {
    border-right: 1px solid #e5ebed;
  }
}

.entry-modal-tag {
  color: white;
  border-radius: 2px;
  font-size: 0.6rem;
  margin-left: 10px;
  padding: 2px 4px;
}

.all-published {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  max-width: 580px;
  margin: 7% auto;

  & h2 {
    margin-bottom: 0.5em;
  }
  & h4 {
    margin-top: 0;
  }
}
.link-holder {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 1rem;
  padding: 0px 3px;
  border-radius: 4px;
  background: #8c53c2;
  color: rgb(83, 97, 113);
  margin-left: 0.25rem;
  height: 15px;
  svg {
    margin-right: 2px;
    height: 14px;
    width: 14px;
  }
  p {
    margin: 0 !important;
    text-decoration: none;
    color: white !important;
    font-size: 0.7rem;
  }
}

.detail-entry-name {
  display: flex;
  align-items: center;
}

.w-auto {
	width: auto !important;
}

.body.keep-left {
  margin-left: 5%;
  padding-top: 40px;
}

.acclaro-ad {
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
  min-height: 490px;
  img {
    width: 300px;
    margin-top: var(--spacing-m);
  }
}

.download-button span {
  display: flex;
  align-items: center;
  margin-right: 5px;
  border-bottom: 1px solid #e5ebed;
}

.language-status {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
}

/******* insight widget ********/
.insights-widget {
  display: flex;
  justify-content: space-around;
  padding-top: var(--spacing-xl);
  padding-bottom: var(--spacing-xl);
  text-align: center;
  [data-card-part='content'] {
    display: flex;
    justify-content: space-around;
  }
}

.insight-stat {
  color: var(--color-text-dark);
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-medium);
}

.insight-divider {
  border-right: 1px solid var(--color-element-light);
  height: 3rem;
}

.pull-to-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.acclaro-news {
  .h3 {
    margin-bottom: 1.5em;
  }
  .news-item {
    border-bottom: 1px solid #e5ebed;
    margin: 1em 0;
    padding-bottom: 0.5em;
    div {
      padding-left: 0;
    }
  }
}
.translations-dashboard {
  .acclaro-ad {
    padding: 0;
    display: block;
    min-height: auto;
    img {
      width: 100%;
      margin-top: 0;
    }
  }
}
.flex-container {
  display: flex;
  align-items: center;
  &.space-between {
    justify-content: space-between;
  }

  .lang-tag {
    color: var(--color-text-dark);
    margin-left: 15px;
  }
}
img.coverage-icon {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.translations-dashboard-content {
  max-width: 1280px;
  margin: auto;
  padding-bottom: 100px;
}

.load-more {
  text-transform: uppercase;
  margin: 1em 0em;
}

.load-more-container {
  max-height: 250px;
  overflow-y: auto;
}

.create-project-dashboard-btn {
  margin-bottom: 2em !important;
  span {
    font-size: 1rem !important;
  }
}

@media (max-width: 760px) {
  table td {
    word-break: break-word;
  }
}

.pagination {
  align-content: center;
  display: flex;
  font-size: 14px;
  justify-content: center;
  padding-left: 0px;
}
.pagination > .previous {
  margin-right: 30px;
}
.pagination > .next {
  margin-left: 30px;
}
.pagination > .previous::before {
  content: '‹';
  margin-right: 5px;
}
.pagination > .next::after {
  content: '›';
  margin-left: 5px;
}
.pagination > .previous:hover,
.pagination > .next:hover {
  background: transparent !important;
  color: var(--color-text-dark);
}
.pagination > .previous > a:hover,
.pagination > .next > a:hover {
  background: transparent !important;
}
.pagination > .previous > a,
.pagination > .next > a {
  margin-left: 0px;
}
.pagination > li {
  align-items: center;
  background: transparent;
  color: var(--color-element-dark);
  display: inline-flex;
}
.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
  border: none;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  text-decoration: none;
  margin-left: 10px;
  padding: 10px;
  cursor: pointer;
}
.pagination > li > a:hover {
  background: var(--color-element-lightest);
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background: var(--color-element-light);
  border: none;
  color: var(--color-text-dark);
}

.checkbox-entity-list {
  align-items: center;
  display: flex;
  border-bottom: 1px solid #d3dce0;
  &:hover {
    background-color: #f7f9fa;
    cursor: pointer;
  }
  li {
    flex-basis: 100%;
    border-bottom: none;
    overflow: hidden;
  }
  & > div:first-child {
    margin-right: 0.5rem;
    padding-left: 10px;
  }
}

.source-content-button-container {
  align-items: center;
  background-color: #f7f9fa;
  border-radius: 6px 6px 0 0;
  border: 1px solid #c3cfd5;
  display: flex;
  justify-content: space-between;
  min-height: 32px;
  padding: 0.5rem;
}

.button-more {
  display: flex;
  align-items: center;
}

.acl-button-dropdown span[class*='Button__Button__inner'] {
  padding: 0 1rem;
}

// Css for custom tab editor
.tab-content-editor {
  margin-top: -30px;
}

.no-margin {
  margin-bottom: 0px !important;
}

.editor-tabs {
  .tab-box {
    box-shadow: inset 0 -2px 0 0 #e5ebed;
  }

  .align-center-tab {
    padding: 5px;
    font-size: 0.875rem;
  }

  .align-center-tab:hover {
    cursor: pointer;
  }

  .align-center-tab.active {
    color: rgb(3, 111, 227);
  }

  .align-center-tab.disabled {
    cursor: not-allowed;
    opacity: 0.4 !important;
  }
}

.advance-options{
  width:96%;
}
